
import { defineComponent } from "@vue/runtime-core";


export default defineComponent({
  data: () => ({
    uniInNumbers: [
      {
        title: '2700+',
        icon: '',
        subtitle: 'студентов'
      },
      {
        title: '50',
        icon: '',
        subtitle: 'образовательных программ'
      },
      {
        title: '72,8',
        icon: '',
        subtitle: 'cредний балл ЕГЭ'
      },
      {
        title: '14',
        icon: '',
        subtitle: '10 - выпускающих и <br/> 4 - базовых кафедр'
      },
    ]
  }),
  methods: {
    getImageURL(path: string) {
      // return new URL(`../assets/${path}`, import.meta.url).href;
    }
  }
})
